import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Account, AccountInfo } from '../types/accountInfo';

@Injectable({
    providedIn: 'root',
})
export class AppStateService {
    readonly httpClient = inject(HttpClient)

    // Define signals
    private userAccount = signal<Account | null>(null);
    // Expose signals as readonly signals
    readonly userAccount$ = this.userAccount.asReadonly();

    fetchUser(): void {
        const accountId = localStorage.getItem('account_id')
        if(!accountId) {
            return;
        }
        this.httpClient
            .get(environment.apiUrl + '/points/account?id=' + accountId, {
                withCredentials: true
            })
            .subscribe({
                next: (response) => {
                    const res: AccountInfo = response as any;
                    if (res.success) {
                        this.userAccount.set(res.message);
                    } else {
                        console.error('Failed to fetch user info:', response);
                    }
                },
                error: (err) => console.error('API error:', err),
            });
    }
}