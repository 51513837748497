import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs';
import { httpSuccess } from 'src/app/types/httpSuccess';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { AccountInfo } from 'src/app/types/accountInfo';
import { SubSink } from 'subsink';
import { environment } from 'src/environments/environment';
import { AppStateService } from 'src/app/services/app-state.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          'max-height': '500px',
          opacity: '1',
          visibility: 'visible'
        })
      ),
      state(
        'out',
        style({
          'max-height': '0px',
          opacity: '0',
          visibility: 'hidden'
        })
      ),
      transition('in => out', [
        animate(
          '300ms ease-in-out',
          style({
            opacity: '0'
          })
        ),
        animate(
          '300ms ease-in-out',
          style({
            'max-height': '0px'
          })
        ),
        animate(
          '300ms ease-in-out',
          style({
            visibility: 'hidden'
          })
        )
      ]),
      transition('out => in', [
        animate(
          '1ms ease-in-out',
          style({
            visibility: 'visible'
          })
        ),
        animate(
          '300ms ease-in-out',
          style({
            'max-height': '500px'
          })
        ),
        animate(
          '300ms ease-in-out',
          style({
            opacity: '1'
          })
        )
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  accountId: string | undefined;
  ishamburgerOpen: boolean = false;
  animationState = 'out';
  isScrolled: boolean = false;
  isToggled: boolean = false;
  isLoggedIn: boolean = false;
  forumsLink: string = '';


  user = this.appState.userAccount$;
  
  toggles: Record<string, boolean> = {
    langToggle: false,
    newsToggle: false,
    supportToggle: false,
    commToggle: false
  };
  private subs = new SubSink();

  toggle(classToggle: string) {
    Object.keys(this.toggles).forEach(t => {
      if (t !== classToggle) {
        this.toggles[t] = false;
      } else {
        this.toggles[classToggle] = !this.toggles[classToggle];
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop > 0) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  constructor(
    private auth: AuthService,
    private authService: AuthService,
    private httpClient: HttpClient,
    private appState: AppStateService
  ) { }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.accountId = localStorage.getItem('account_id')!;
    this.checkLogin();

    if (environment.language === 'cn')
      this.forumsLink = 'https://forums.cn.duskhaven.net';
    else this.forumsLink = 'https://forums.duskhaven.net';

    if (this.accountId != undefined || this.accountId != null) {
      //this.getUserPoints();
      this.appState.fetchUser();
    }

    this.subs.sink = this.auth.refresh$.subscribe(() => {
      this.ngOnInit();
    });
  }

  checkLogin() {
    this.subs.sink = this.authService.isLoggedIn().subscribe({
      next: response => {
        if (response) this.isLoggedIn = true;
        else { 
          this.isLoggedIn = false; 
          this.accountId = undefined; 
        }
      },
      error: error => {
        this.isLoggedIn = false;
      }
    });
  }

  openHamburger(): void {
    this.ishamburgerOpen = !this.ishamburgerOpen;
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  logout() {
    this.authService.logout();
  }
}
