import { Injectable, OnDestroy, inject } from '@angular/core';
import { ILogin } from '../types/login';
import { Subject, catchError, filter, fromEvent, map, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _refresh$ = new Subject<void>();
  private httpClient = inject(HttpClient);
  private router = inject(Router);

  get refresh$() {
    return this._refresh$;
  }

  isLoggedIn() {
    const accountId = localStorage.getItem('account_id');
    if (!accountId) {
      return of(false);
    }
    return this.httpClient
      .post(
        environment.apiUrl + '/users/checklogin',
        {},
        { withCredentials: true }
      )
      .pipe(
        map(response => true),
        catchError(error => {
          // Clear invalid session
          this.logout();
          return of(false);
        })
      );
  }

  logout(): void {
    this.httpClient
      .post(environment.apiUrl + '/users/logout', {}, { withCredentials: true })
      .subscribe({
        next: (response) => {
          localStorage.removeItem('account_id');
          localStorage.removeItem('expires');
          localStorage.clear();
          this.router.navigate(['/login']);
          this._refresh$.next();
        },
        error: (error) => {},
      });
  }

  login(account_id: number, expires: string): void {
    localStorage.setItem('account_id', account_id.toString());
    localStorage.setItem('expires', expires);
    this._refresh$.next();
  }
}
