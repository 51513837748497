import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { httpError } from 'src/app/types/httpError';
import { httpSuccess } from 'src/app/types/httpSuccess';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;
  private subs = new SubSink();
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private httpClient: HttpClient,
    private toastr: ToastrService
  ) {

    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      password: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)]],
      confirmPassword: ['', Validators.required],
      acceptTerms: [false, Validators.required]
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    
  }

  get f() {
    return this.registerForm.controls;
  }

  checkEmail(email: string | null) {
    const expression: RegExp = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

    if (!email) return false;

    return expression.test(email);
  }

  isFormInvalid(): boolean {
    return this.registerForm.invalid;
  }

  isTermsAccepted(): boolean {
    if (!this.f['acceptTerms'].value) {
      this.toastr.error('You must accept the terms and conditions to continue.', 'Validation Error');
      return false;
    }
    return true;
  }

  doPasswordsMatch(): boolean {
    if (this.f['confirmPassword'].value !== this.f['password'].value) {
      this.toastr.error('Your passwords do not match.', 'Validation Error');
      return false;
    }
    return true;
  }

  get validEmail() {
    console.log(this.registerForm.get("email"));
    return this.registerForm.get("email")?.pristine || this.registerForm.get("email")?.valid;
  }

  isValidEmail(): boolean {
    if (!this.checkEmail(this.f['email'].value)) {
      this.toastr.error('Invalid email address.', 'Validation Error');
      return false;
    }
    return true;
  }

  register() {
    if (this.isFormInvalid()) return;
    if (!this.isTermsAccepted()) return;
    if (!this.doPasswordsMatch()) return;
    if (!this.isValidEmail()) return;

    const requestBody = {
      name: this.f['name'].value,
      password: this.f['password'].value,
      email: this.f['email'].value
    };

    this.subs.sink = this.httpClient
      .post(environment.apiUrl + '/users/register', requestBody)
      .subscribe(
        response => {
          const res: httpSuccess = response as any;
          this.toastr.success('You can now log in','Account successfully created!')
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 3000);
        },
        error => {
          const err: HttpErrorResponse = error;
          let msg = err.message;
          if (err.status === 401) {
            const e: httpError = err.error;
            msg = e.message;
          }
          this.toastr.error('Something went wrong while creating your account, try later again. If you need more help, join our Discord.','Error! ')
        }
      );
  }
}
