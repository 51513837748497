import { NgModule } from '@angular/core';
import { RouterModule, Routes, provideRouter } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/Home/home.component';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuardService } from './guards/auth.guard';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { ResetComponent } from './pages/reset/reset.component';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { TosComponent } from './pages/legal/tos/tos.component';
import { PrivacypolicyComponent } from './pages/legal/privacypolicy/privacypolicy.component';
import { RefundpolicyComponent } from './pages/legal/refundpolicy/refundpolicy.component';
import { CustomComponent } from './pages/custom/custom.component';
import { NewsComponent } from './pages/news/news.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { TimelineComponent } from './pages/timeline/timeline.component';
import { HowToConnectComponent } from './pages/how-to-connect/how-to-connect.component';
import { LogsComponent } from './pages/dashboard/logs/logs.component';
import { IssuesComponent } from './pages/issues/issues.component';
import { NewIssueComponent } from './pages/issues/new-issue/new-issue.component';
import { SingleIssueComponent } from './pages/issues/single-issue/single-issue.component';
import { ActivateComponent } from './pages/activate/activate.component';
import { DonateComponent } from './pages/donate/donate.component';
import { StoreComponent } from './pages/store/store.component';
import { SingleItemComponent } from './pages/store/single-item/single-item.component';
import { CartComponent } from './pages/cart/cart.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/:redirect', component: LoginComponent },
  { path: 'forgot', component: ForgotComponent },
  {
    path: 'dashboard/:setDisplayName',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  },
  { path: 'reset/:token/:id', component: ResetComponent },
  { path: 'blog/:id', component: BlogComponent },
  { path: 'termsofservice', component: TosComponent },
  { path: 'privacypolicy', component: PrivacypolicyComponent },
  { path: 'refundpolicy', component: RefundpolicyComponent },
  { path: 'custom', component: CustomComponent },
  { path: 'news', component: NewsComponent },
  { path: 'changelog', component: ChangelogComponent },
  { path: 'timeline', component: TimelineComponent },
  { path: 'how-to-connect', component: HowToConnectComponent },
  { path: 'logs', component: LogsComponent },
  { path: 'issues', component: IssuesComponent },
  {
    path: 'issues/new',
    canActivate: [AuthGuardService],
    component: NewIssueComponent,
  },
  { path: 'issues/:id', component: SingleIssueComponent },
  { path: 'activate/:email', component: ActivateComponent },
  {
    path: 'donate',
    component: DonateComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [provideRouter(routes)],
})
export class AppRoutingModule {}
