import {
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ErrorComponent } from 'src/app/modals/error/error.component';
import { SuccessComponent } from 'src/app/modals/success/success.component';
import { Router } from '@angular/router';
import { httpSuccess } from 'src/app/types/httpSuccess';
import { httpError } from 'src/app/types/httpError';
import { AccountInfo } from 'src/app/types/accountInfo';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Markdown } from '@ckeditor/ckeditor5-markdown-gfm';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { SubSink } from 'subsink';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-issue',
  templateUrl: './new-issue.component.html',
  styleUrl: './new-issue.component.scss'
})
export class NewIssueComponent implements OnInit, OnDestroy {
  categories: string[] = [
    'Choose a category',
    'Website',
    'Launcher',
    'Client',
    'Other'
  ];

  priority: string[] = [
    'Select priority',
    'Game Breaking',
    'High',
    'Medium',
    'Low'
  ];

  public Editor = ClassicEditor;
  public config = {
    toolbar: {}
  };
  public desc: string = '';

  issueForm = new FormGroup({
    title: new FormControl(''),
    category: new FormControl(''),
    priority: new FormControl('')
  });

  modalComponentRef:
    | ComponentRef<ErrorComponent>
    | undefined
    | ComponentRef<SuccessComponent>;

  private subs = new SubSink();
  constructor(
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.issueForm = this.formBuilder.group({
      title: ['', Validators.required],
      category: ['', Validators.required],
      priority: ['', Validators.required]
    });

    this.checkDisplayName();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    if (this.modalComponentRef) {
      this.modalComponentRef.destroy();
    }
  }

  checkDisplayName() {
    this.subs.sink = this.httpClient
      .get(
        environment.apiUrl +
          '/points/userPoints?id=' +
          localStorage.getItem('account_id'),
        { withCredentials: true }
      )
      .subscribe(
        response => {
          const res: AccountInfo = response as any;
          if (res.message.displayName === 'null') {
            this.sendToDisplayName();
          }
        },
        error => {}
      );
  }

  sendToDisplayName() {
    this.showModal(
      'No display name set, please set one in the dashboard!',
      false,
      undefined,
      () => {
        this.router.navigate(['/dashboard']);
      }
    );
  }

  showModal(
    innerText: string,
    isSuccessful: boolean,
    issueId?: number,
    func?: () => void
  ) {
    this.modalComponentRef = isSuccessful
      ? this.viewContainerRef.createComponent(SuccessComponent)
      : this.viewContainerRef.createComponent(ErrorComponent);
    this.modalComponentRef.instance.text = innerText;
    this.modalComponentRef.instance.confirmAction = isSuccessful
      ? () => {
          this.router.navigate(['/issues/' + issueId]);
        }
      : () => {
          if (func) func();
          else this.modalComponentRef?.instance.closeModal();
        };
    this.subs.sink = this.modalComponentRef.instance.closed.subscribe(() => {
      this.modalComponentRef?.destroy();
    });
  }

  get f() {
    return this.issueForm.controls;
  }

  submitIssue() {
    if (this.issueForm.invalid || this.desc === '') {
      return;
    }

    if (localStorage.getItem('account_id') === undefined) {
      return;
    }

    if (
      this.f['category'].value === this.categories[0] ||
      this.f['priority'].value === this.priority[0]
    ) {
      this.showModal('Please select a value for category and priority', false);
      return;
    }

    let bugBody = `${this.desc}`;

    const requestBody = {
      title: this.f['title'].value,
      body: bugBody,
      category: this.f['category'].value,
      priority: this.f['priority'].value,
      account_id: localStorage.getItem('account_id')
    };

    this.subs.sink = this.httpClient
      .post(environment.apiUrl + '/issues/createNew', requestBody, {
        withCredentials: true
      })
      .subscribe(
        response => {
          const res: httpSuccess = response as any;
          this.showModal(
            'Successfully submitted issue',
            true,
            res.message as number
          );
          setTimeout(() => {
            this.router.navigate(['/issues/' + res.message]);
          }, 2000);
        },
        error => {
          const err: HttpErrorResponse = error;
          let msg = err.message;
          if (err.status === 401) {
            const e: httpError = err.error;
            msg = e.message;
          }

          this.showModal(msg, false, undefined, () => {
            this.router.navigate(['/dashboard']);
          });
        }
      );
  }

  public setDesc({ editor }: ChangeEvent) {
    const data = editor.data.get();
    this.desc = data;
  }
}
